<script setup lang="ts">
import { getTranslatedProperty } from "@shopware-pwa/helpers-next";

const { navigationElements } = useNavigation();

const { formatLink } = useInternationalization();
</script>

<template>
  <section class="s-gutter my-15">
    <div class="c-width-small">
      <h1 class="mb-12 text-h1 text-maas-typography-headline-primary">{{ $t("pageNotFound.header") }}</h1>
      <p class="mb-12 text-h3">
        {{ $t("pageNotFound.text") }}
      </p>
      <p class="mb-6 text-h5">Vielleicht finden Sie hier was Sie suchen:</p>
      <div class="flex flex-wrap gap-4">
        <template v-for="navigationItem in navigationElements" :key="navigationItem.id">
          <SharedFormButton
            v-if="navigationItem.customFields?.conne_navigation_position === 'left'"
            layout="secondaryDark"
            :to="formatLink('/' + navigationItem.seoUrls?.[0]?.seoPathInfo)"
            :class="{
              'text-maas-typography-navigation-red border-maas-typography-navigation-red': getTranslatedProperty(navigationItem, 'name').includes('%')
            }"
          >
            {{ navigationItem.name }}
          </SharedFormButton>
        </template>
      </div>
    </div>
  </section>
</template>
